// The file contents for the current environment will overwrite these during build.
// Do not manually change this file. Your changes will be overwritten on build.
// This file was copied from: src/environments/environment.staging.ts

export const environment = {
  broadcastContentTypeId: '4514894340685824',
  businessAppsDirectoryId: '5753284037967872',
  caseManagementApiUrl: 'https://casemanagement-service-staging.eex.ea.com',
  caseManagementApiTokenAuth: 'bd015b03a419a62e9fd4260fac8b843e',
  cloudfrontUrl: 'https://assets-eaworld-staging.work.ea.com',
  coveo: {
    tokenExchangeEndpoint: 'https://ea-search-service-staging.eex.ea.com/api/v1/token?source=eaworld'
  },
  customerId: '5772395182817280',
  defaultDirectoryMaxResults: 250,
  eaPagesContentTypeId: '6363395383099392',
  enterpriseSearchNotificationDirectoryId: '7543411926348017',
  feedAllId: '4976071101382656',
  headerId: '6192905805365248',
  lumappsApiV2Host: 'https://go-cell-003.api.lumapps.com',
  lumappsServiceUrl: 'https://lumapps-service-staging.atlas-services.ea.com',
  minimizeCode: false,
  name: 'staging',
  primaryInstanceId: '4888038834438144',
  retinaPortalUrl: 'https://login.work.ea.com',
  rootEnvironmentPath: '',
  rootSitePath: '/home',
  searchCloudfrontUrl: 'https://search-staging.work.ea.com',
  showDebugInfo: false,
  showVersionInfo: true,
  siteAssetsUrl: 'https://eaworld-site-assets-staging.eex.ea.com',
  siteFeedbackEmail: 'EAWorld_Feedback_DL_TEST_Stage_ENV@ea.com',
  userDirectoryContentId: '6099262981013504',
  verboseLogging: true,
  workplaceLocationsDirectoryId: '8274957867103181'
};
